export default [
  {
    title: 'Customers',
    icon: 'UsersIcon',
    resource: 'customer.customers',
    action: 'read',
    children: [
      {
        title: 'Customers',
        route: 'customers',
        resource: 'customer.customers',
        action: 'read',
      },
      {
        title: 'Import',
        route: 'customers-import',
        resource: 'customer.import',
        action: 'read',
      },
    ],
  },
]
