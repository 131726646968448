import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";
import i18n from '@/libs/i18n'

export default function useHotHits() {
  const hotHitSortBy = ref('lastCallTime')
  const hotHitIsSortDirDesc = ref(false)
  const refHotHitTable = ref(null)
  const hotHitTotal = ref(0)
  const hotHitCurrentPage = ref(1)
  const hotHitPerPage = ref(50)
  const hotHitColumns = [
    { key: "stateCode", sortable: true, label: i18n.t('Modules.Customer.Label.State'), thClass: 'col-customer-state' },
    { key: "name", sortable: true, label: i18n.t('Modules.Customer.Label.Customer'), thClass: 'col-customer-name' },
    { key: "note", sortable: false, label: i18n.t('Modules.Customer.Label.Note'), thClass: 'col-customer-note' },
    { key: "subStatus", sortable: false, label: i18n.t('Modules.Customer.Label.Status') },
    { key: "callResult", sortable: false, label: i18n.t('Modules.Customer.Label.Call Result') },
    { key: "lastCallTime", sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
    { key: "actions", sortable: false, label: '' },
  ];
  const hotHitSubStatusItems = ['all', 'potential', 'do_not_forget', 'other', 'not_enough_info']
  const hotHitSubStatusChoosed = ref(['all'])

  const fetchHotHits = async (ctx) => {
    try {
      var offset = hotHitPerPage.value * (hotHitCurrentPage.value - 1)
      if(hotHitIsSortDirDesc.value) {
        if(hotHitSubStatusChoosed.value.includes('all')) {
          const hotHitItems = await userDB.hotHits.orderBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value).desc().offset(offset).limit(hotHitPerPage.value).toArray();
          const hotHitTotalItems = await userDB.hotHits.orderBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value).desc().toArray();
          hotHitTotal.value = hotHitTotalItems.length;
          return hotHitItems
        } else {
          const hotHitItems = await userDB.hotHits.where('subStatus').anyOf(hotHitSubStatusChoosed.value).reverse().offset(offset).limit(hotHitPerPage.value).sortBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value);
          const hotHitTotalItems = await userDB.hotHits.where('subStatus').anyOf(hotHitSubStatusChoosed.value).reverse().sortBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value);
          hotHitTotal.value = hotHitTotalItems.length;
          return hotHitItems
        }
      } else {
        if(hotHitSubStatusChoosed.value.includes('all')) {
          const hotHitItems = await userDB.hotHits.orderBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value).offset(offset).limit(hotHitPerPage.value).toArray();
          const hotHitTotalItems = await userDB.hotHits.orderBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value).toArray();
          hotHitTotal.value = hotHitTotalItems.length;
          return hotHitItems
        } else {
          const hotHitItems = await userDB.hotHits.where('subStatus').anyOf(hotHitSubStatusChoosed.value).offset(offset).limit(hotHitPerPage.value).sortBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value);
          const hotHitTotalItems = await userDB.hotHits.where('subStatus').anyOf(hotHitSubStatusChoosed.value).sortBy(hotHitSortBy.value == 'name' ? 'nameSearch' : hotHitSortBy.value);
          hotHitTotal.value = hotHitTotalItems.length;
          return hotHitItems
        }
      }
    } catch (error) {
      return []
    }
  }

  const refetchHotHits = () => {
    refHotHitTable.value.refresh()
  }

  watch([hotHitCurrentPage, hotHitSubStatusChoosed], () => {
    refetchHotHits()
  })

  return {
    hotHitSortBy,
    hotHitIsSortDirDesc,
    refHotHitTable,
    hotHitTotal,
    hotHitColumns,
    hotHitSubStatusItems,
    hotHitSubStatusChoosed,
    hotHitCurrentPage,
    hotHitPerPage,
    fetchHotHits,
    refetchHotHits,
  }
}