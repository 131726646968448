import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";
import i18n from '@/libs/i18n'

export default function usePotentials() {
  const potentialSortBy = ref('lastCallTime')
  const potentialIsSortDirDesc = ref(false)
  const refPotentialTable = ref(null)
  const potentialTotal = ref(0)
  const potentialCurrentPage = ref(1)
  const potentialPerPage = ref(50)
  const potentialColumns = [
    { key: "stateCode", sortable: true, label: i18n.t('Modules.Customer.Label.State'), thClass: 'col-customer-state' },
    { key: "name", sortable: true, label: i18n.t('Modules.Customer.Label.Customer'), thClass: 'col-customer-name' },
    { key: "note", sortable: false, label: i18n.t('Modules.Customer.Label.Note'), thClass: 'col-customer-note' },
    { key: "subStatus", sortable: false, label: i18n.t('Modules.Customer.Label.Status') },
    { key: "callResult", sortable: false, label: i18n.t('Modules.Customer.Label.Call Result') },
    { key: "lastCallTime", sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
    { key: "actions", sortable: false, label: '' },
  ];
  const potentialSubStatusItems = ['all', 'approach', 'potential', 'do_not_forget', 'other', 'not_enough_info']
  const potentialSubStatusChoosed = ref(['all'])

  const fetchPotentials = async (ctx) => {
    try {
      var offset = potentialPerPage.value * (potentialCurrentPage.value - 1)
      if(potentialIsSortDirDesc.value) {
        if(potentialSubStatusChoosed.value.includes('all')) {
          const potentialItems = await userDB.potentials.orderBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value).desc().offset(offset).limit(potentialPerPage.value).toArray();
          const potentialTotalItems = await userDB.potentials.orderBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value).desc().toArray();
          potentialTotal.value = potentialTotalItems.length;
          return potentialItems
        } else {
          const potentialItems = await userDB.potentials.where('subStatus').anyOf(potentialSubStatusChoosed.value).reverse().offset(offset).limit(potentialPerPage.value).sortBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value);
          const potentialTotalItems = await userDB.potentials.where('subStatus').anyOf(potentialSubStatusChoosed.value).reverse().sortBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value);
          potentialTotal.value = potentialTotalItems.length;
          return potentialItems
        }
      } else {
        if(potentialSubStatusChoosed.value.includes('all')) {
          const potentialItems = await userDB.potentials.orderBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value).offset(offset).limit(potentialPerPage.value).toArray();
          const potentialTotalItems = await userDB.potentials.orderBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value).toArray();
          potentialTotal.value = potentialTotalItems.length;
          return potentialItems
        } else {
          const potentialItems = await userDB.potentials.where('subStatus').anyOf(potentialSubStatusChoosed.value).offset(offset).limit(potentialPerPage.value).sortBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value);
          const potentialTotalItems = await userDB.potentials.where('subStatus').anyOf(potentialSubStatusChoosed.value).sortBy(potentialSortBy.value == 'name' ? 'nameSearch' : potentialSortBy.value);
          potentialTotal.value = potentialTotalItems.length;
          return potentialItems
        }
      }
    } catch (error) {
      return []
    }
  }
  const refetchPotentials = () => {
    refPotentialTable.value.refresh()
  }

  watch([potentialCurrentPage, potentialSubStatusChoosed], () => {
    refetchPotentials()
  })

  return {
    potentialSortBy,
    potentialIsSortDirDesc,
    refPotentialTable,
    potentialTotal,
    potentialColumns,
    potentialSubStatusItems,
    potentialSubStatusChoosed,
    potentialCurrentPage,
    potentialPerPage,
    fetchPotentials,
    refetchPotentials,
  }
}