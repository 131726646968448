import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";
import i18n from '@/libs/i18n'

export default function useColdCalls() {
  const coldCallSortBy = ref('lastCallTime')
  const coldCallIsSortDirDesc = ref(false)
  const refColdCallTable = ref(null)
  const coldCallTotal = ref(0)
  const coldCallCurrentPage = ref(1)
  const coldCallPerPage = ref(50)
  const coldCallColumns = [
    { key: "stateCode", sortable: true, label: i18n.t('Modules.Customer.Label.State'), thClass: 'col-customer-state' },
    { key: "name", sortable: true, label: i18n.t('Modules.Customer.Label.Customer'), thClass: 'col-customer-name' },
    { key: "note", sortable: false, label: i18n.t('Modules.Customer.Label.Note') },
    { key: "lastCallTime", sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
  ];

  const fetchColdCalls = async (ctx) => {
    try {
      var offset = coldCallPerPage.value * (coldCallCurrentPage.value - 1)
      if(coldCallIsSortDirDesc.value) {
        const coldCallItems = await userDB.coldCalls.orderBy(coldCallSortBy.value == 'name' ? 'nameSearch' : coldCallSortBy.value).desc().offset(offset).limit(coldCallPerPage.value).toArray();
        const coldCallTotalItems = await userDB.coldCalls.orderBy(coldCallSortBy.value == 'name' ? 'nameSearch' : coldCallSortBy.value).desc().toArray();
        coldCallTotal.value = coldCallTotalItems.length;
        return coldCallItems
      } else {
        const coldCallItems = await userDB.coldCalls.orderBy(coldCallSortBy.value == 'name' ? 'nameSearch' : coldCallSortBy.value).offset(offset).limit(coldCallPerPage.value).toArray();
        const coldCallTotalItems = await userDB.coldCalls.orderBy(coldCallSortBy.value == 'name' ? 'nameSearch' : coldCallSortBy.value).toArray();
        coldCallTotal.value = coldCallTotalItems.length;
        return coldCallItems
      }
    } catch (error) {
      return []
    }
  }

  const refetchColdCalls = () => {
    refColdCallTable.value.refresh()
  }

  watch([coldCallCurrentPage], () => {
    refetchColdCalls()
  })

  return {
    coldCallSortBy,
    coldCallIsSortDirDesc,
    refColdCallTable,
    coldCallTotal,
    coldCallColumns,
    coldCallCurrentPage,
    coldCallPerPage,
    fetchColdCalls,
    refetchColdCalls,
  }
}