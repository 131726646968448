import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";
import i18n from '@/libs/i18n'

export default function useDenieds() {
  const deniedSortBy = ref('lastCallTime')
  const deniedIsSortDirDesc = ref(false)
  const refDeniedTable = ref(null)
  const deniedTotal = ref(0)
  const deniedCurrentPage = ref(1)
  const deniedPerPage = ref(50)
  const deniedColumns = [
    { key: "stateCode", sortable: true, label: i18n.t('Modules.Customer.Label.State'), thClass: 'col-customer-state' },
    { key: "name", sortable: true, label: i18n.t('Modules.Customer.Label.Customer'), thClass: 'col-customer-name' },
    { key: "note", sortable: false, label: i18n.t('Modules.Customer.Label.Note') },
    { key: "lastCallTime", sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
    { key: "actions", sortable: false, label: '' },
  ];

  const fetchDenieds = async (ctx) => {
    try {
      var offset = deniedPerPage.value * (deniedCurrentPage.value - 1)
      if(deniedIsSortDirDesc.value) {
        const deniedItems = await userDB.denieds.orderBy(deniedSortBy.value == 'name' ? 'nameSearch' : deniedSortBy.value).desc().offset(offset).limit(deniedPerPage.value).toArray();
        const deniedTotalItems = await userDB.denieds.orderBy(deniedSortBy.value == 'name' ? 'nameSearch' : deniedSortBy.value).desc().toArray();
        deniedTotal.value = deniedTotalItems.length;
        return deniedItems
      } else {
        const deniedItems = await userDB.denieds.orderBy(deniedSortBy.value == 'name' ? 'nameSearch' : deniedSortBy.value).offset(offset).limit(deniedPerPage.value).toArray();
        const deniedTotalItems = await userDB.denieds.orderBy(deniedSortBy.value == 'name' ? 'nameSearch' : deniedSortBy.value).toArray();
        deniedTotal.value = deniedTotalItems.length;
        return deniedItems
      }
    } catch (error) {
      return []
    }
  }

  const refetchDenieds = () => {
    refDeniedTable.value.refresh()
  }

  watch([deniedCurrentPage], () => {
    refetchDenieds()
  })

  return {
    deniedSortBy,
    deniedIsSortDirDesc,
    refDeniedTable,
    deniedTotal,
    deniedColumns,
    deniedCurrentPage,
    deniedPerPage,
    fetchDenieds,
    refetchDenieds,
  }
}