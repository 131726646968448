import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";
import i18n from '@/libs/i18n'

export default function useClosedCases() {
  const closedCaseSortBy = ref('lastCallTime')
  const closedCaseIsSortDirDesc = ref(false)
  const refClosedCaseTable = ref(null)
  const closedCaseTotal = ref(0)
  const closedCaseCurrentPage = ref(1)
  const closedCasePerPage = ref(50)
  const closedCaseColumns = [
    { key: "stateCode", sortable: true, label: i18n.t('Modules.Customer.Label.State'), thClass: 'col-customer-state' },
    { key: "name", sortable: true, label: i18n.t('Modules.Customer.Label.Customer'), thClass: 'col-customer-name' },
    { key: "note", sortable: false, label: i18n.t('Modules.Customer.Label.Note') },
    { key: "lastCallTime", sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
    { key: "actions", sortable: false, label: '' },
  ];

  const fetchClosedCases = async (ctx) => {
    try {
      var offset = closedCasePerPage.value * (closedCaseCurrentPage.value - 1)
      if(closedCaseIsSortDirDesc.value) {
        const closedCaseItems = await userDB.closedCases.orderBy(closedCaseSortBy.value == 'name' ? 'nameSearch' : closedCaseSortBy.value).desc().offset(offset).limit(closedCasePerPage.value).toArray();
        const closedCaseTotalItems = await userDB.closedCases.orderBy(closedCaseSortBy.value == 'name' ? 'nameSearch' : closedCaseSortBy.value).desc().toArray();
        closedCaseTotal.value = closedCaseTotalItems.length;
        return closedCaseItems
      } else {
        const closedCaseItems = await userDB.closedCases.orderBy(closedCaseSortBy.value == 'name' ? 'nameSearch' : closedCaseSortBy.value).offset(offset).limit(closedCasePerPage.value).toArray();
        const closedCaseTotalItems = await userDB.closedCases.orderBy(closedCaseSortBy.value == 'name' ? 'nameSearch' : closedCaseSortBy.value).toArray();
        closedCaseTotal.value = closedCaseTotalItems.length;
        return closedCaseItems
      }
    } catch (error) {
      return []
    }
  }

  const refetchClosedCases = () => {
    refClosedCaseTable.value.refresh()
  }

  watch([closedCaseCurrentPage], () => {
    refetchClosedCases()
  })

  return {
    closedCaseSortBy,
    closedCaseIsSortDirDesc,
    refClosedCaseTable,
    closedCaseTotal,
    closedCaseColumns,
    closedCaseCurrentPage,
    closedCasePerPage,
    fetchClosedCases,
    refetchClosedCases,
  }
}