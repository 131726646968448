import { ref, watch } from '@vue/composition-api'
import { userDB } from "@/database/userDB";

export default function useAutoSuggest(props) {
  const filteredData = ref({})

  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'Admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */

  const filterColdCalls = async (query) => {
    const results = await userDB.coldCalls.orderBy('name').limit(props.searchLimit).filter((item) => {
      return searchByName(item, query) || searchByPhone(item, query)
    }).toArray()
    return results;
  }

  const filterPotentials = async (query) => {
    const results = await userDB.potentials.orderBy('name').limit(props.searchLimit).filter((item) => {
      return searchByName(item, query) || searchByPhone(item, query)
    }).toArray()
    return results;
  }

  const filterHotHits = async (query) => {
    const results = await userDB.hotHits.orderBy('name').limit(props.searchLimit).filter((item) => {
      return searchByName(item, query) || searchByPhone(item, query)
    }).toArray()
    return results;
  }

  const filterDenieds = async (query) => {
    const results = await userDB.denieds.orderBy('name').limit(props.searchLimit).filter((item) => {
      return searchByName(item, query) || searchByPhone(item, query)
    }).toArray()
    return results;
  }

  const filterClosedCases = async (query) => {
    const results = await userDB.closedCases.orderBy('name').limit(props.searchLimit).filter((item) => {
      return searchByName(item, query) || searchByPhone(item, query)
    }).toArray()
    return results;
  }

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearchQueryUpdate = async val => {
    if (val === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      // Search in Cold Calls
      queriedData['cold_call'] = await filterColdCalls(val)
      // Search in Potentials
      queriedData['potential'] = await filterPotentials(val)
      // Search in Hot Hits
      queriedData['hothit'] = await filterHotHits(val)
      // Search in Denieds
      queriedData['denied'] = await filterDenieds(val)
      // Search in Closed Cases
      queriedData['closed_case'] = await filterClosedCases(val)
      filteredData.value = queriedData
    }
  }

  watch(searchQuery, val => handleSearchQueryUpdate(val))

  const searchByName = (item, query) => {
    return item.nameSearch.includes(query.toLowerCase())
  }

  const searchByPhone = (item, query) => {
    return item.phoneNumberSearch.includes(query.replace(/[^a-zA-Z0-9]/g, "").toLowerCase())
  }

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
    searchByName,
    searchByPhone
  }
}
